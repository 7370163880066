<template>
  <section id="listaManads">
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb></Breadcrumb>

      <div class="cabecalho-inputs d-flex row">
        <div
          id="filter-input-container"
          class="cabecalho-tools d-flex justify-content-between"
        >
          <input
            class="cabecalho-input"
            id="filter-input"
            type="text"
            placeholder="Código de Oportunidade, Empresa, CNPJ..."
            v-model="searchQuery"
          />
          <!-- <button :disabled="loadingFilter" class="btn" type="submit" variant="dark"> -->
          <button class="btn" type="submit" variant="dark">
            <Icon :iconName="'search'" class="nav-icon" />
          </button>
        </div>
        <button class="cabecalho-tools cabecalho-btn" @click="btnModalTeses()">
          Enviar Arquivos
          <Icon :iconName="'upload'" class="nav-icon" />
        </button>
        <!--<div class="cabecalho-tools cabecalho-btn">
          <input class="cabecalho-input" type="text" placeholder="status: Arquivos Enviados">
          <Icon :iconName='"chevron-down"' class="nav-icon"/>
        </div>-->
      </div>
    </div>

    <div class="jumbotron">
      <b-overlay
        :show="loadingSpinner"
        rounded="sm"
        opacity="0.6"
        spinner-small
        spinner-variant="secondary"
      >
        <div class="p-1">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" @click="sort('nCodOp')">
                  Código da Oportunidade
                </th>
                <th scope="col" @click="sort('nome')">Empresa</th>
                <th scope="col">CNPJ</th>
                <th scope="col" @click="sort('dataRegistro')">Data de Envio</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="cursor: pointer"
                v-for="(manad, index) in filteredListaManads"
                :key="index"
                @click="rowClick(manad)"
              >
                <td scope="row">#{{ manad.ncodOp }}</td>
                <td>{{ manad.nome_Contribuinte.toUpperCase() }}</td>
                <td>{{ formatCnpj(manad.cnpj) }}</td>
                <td>{{ formatDatePTBR(manad.data_Hora_Criacao_Registro) }}</td>
                <!-- <td><button class="btn btn-outline-blue" @click.prevent="fetchCalculoInss(manad.ncodOp)">Compensação</button></td> -->
                <td style="display: flex; justify-content: center">
                  <button
                    type="button"
                    @click.prevent="deleteManad(manad)"
                    class="btn btn-sm btn-outline-danger"
                  >
                    <font-awesome-icon icon="trash-can" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <div class="pagination-nav justify-content-center row my-1">
        <button @click="pagNavigation('first')">
          <Icon :iconName="'chevron-double-left'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('previous')">
          <Icon :iconName="'chevron-left'" class="nav-icon" />
        </button>
        <button id="pag-current-page">{{ currentPage }}</button>
        <button @click="pagNavigation('next')">
          <Icon :iconName="'chevron-right'" class="nav-icon" />
        </button>
        <button @click="pagNavigation('last')">
          <Icon :iconName="'chevron-double-right'" class="nav-icon" />
        </button>
      </div>
    </div>
  </section>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.11/vue.js"></script>
<script src="https://unpkg.com/bootstrap-vue@2.6.1/dist/bootstrap-vue.min.js"></script>
<!-- <link
  href="https://unpkg.com/bootstrap-vue@2.6.1/dist/bootstrap-vue.css"
  rel="stylesheet"
/>
<link
  href="https://unpkg.com/bootstrap@4.4.1/dist/css/bootstrap.min.css"
  rel="stylesheet"
/> -->

<script>
const API_URL = process.env.VUE_APP_URL_API;
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
import Icon from "@/Components/Icon/icon.vue";
import inssService from "@/services/teses/inss.service";
import { router } from "@/router";
import HelperService from "@/services/helper.service.js";

export default {
  name: "ListaManads",
  components: {
    Breadcrumb,
    Icon,
  },

  data() {
    return {
      loadingSpinner: false,
      listaManads: [],
      currentSort: "dataImportacao",
      currentSortDir: "desc",
      small: true,
      searchQuery: "",
      currentPage: 1,
      pageSize: 15,
      returnTeses: false,
      DeleteManad: "",
    };
  },

  mounted() {
    this.getListaManads();
  },

  methods: {
    // Returns a list of companies that already sent its manad file
    // Have the following rows: NCodOp, Nome, CNPJ, Data de Registro
    async getListaManads() {
      (this.loadingSpinner = true),
        await inssService
          .getListaManad()
          .then((response) => {
            if (response.status == 200) {
              this.listaManads = response.data.dados;
            }
          })
          .catch((error) => {
            // return HelperService.callMakeToast(error, "error");
            this.$toast.open({ message: error, type: "error", duration: 2000 });
          });
      this.loadingSpinner = false;
    },

    async deleteManad(manad) {
      event.stopPropagation();
      console.log("deleteManad");
      (this.loadingSpinner = true),
        await inssService
          .DeleteManad(manad.ncodOp, manad.cnpj)
          .then((response) => {
            console.log("response --> ", response);
            if (response.status == 200 && response.data == true) {
              this.showMsgDeleteManad();
              console.log("delete manad ok");
            } else {
              this.$toast.open({
                message: "possui calculo, não pode excluir",
                type: "error",
                duration: 4000,
              });
            }
          })
          .catch((error) => {
            this.$toast.open({ message: error, type: "error", duration: 2000 });
          });
      this.loadingSpinner = false;
    },

    showMsgDeleteManad() {
      this.DeleteManad = "";
      this.$bvModal
        .msgBoxOk("Todos os manads deste NcodOp foram excluidos!", {
          title: "Confirmação",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          (this.DeleteManad = value), this.getListaManads();
        })
        .catch((err) => {
          // An error occurred
        });
    },

    rowClick(file) {
      this.$router.push(`/calculo/inss/${file.ncodOp}/`);
    },

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    fetchCalculoInss(nCodOp) {
      this.$router.push(`/manadRubricaPesquisa/${nCodOp}/`);
    },

    formatDatePTBR(date) {
      const formatDate = date.split("T")[0].split("-").reverse().join("/");
      return formatDate;
    },

    formatCnpj(cnpj) {
      const formatedCnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      return formatedCnpj;
    },

    pagNavigation(page) {
      switch (page) {
        case "first":
          this.currentPage = 1;
          break;
        case "previous":
          if (this.currentPage > 1) this.currentPage--;
          break;
        case "next":
          if (this.currentPage * this.pageSize < this.listaManads.length)
            this.currentPage++;
          break;
        case "last":
          this.currentPage = Math.ceil(this.listaManads.length / this.pageSize);
          break;
        default:
          try {
            if (page <= this.listaManads.length / this.pageSize && page >= 1)
              this.currentPage = page;
          } catch (error) {
            console.log("Cannot go to the page " + page);
          }
          break;
      }
    },

    teses(params) {
      this.returnTeses = params;
    },

    btnModalTeses() {
      this.$store.dispatch("updateshowAndHideModalTeses", true, "inss");
    },

    calculoRubrica(nCodOp) {
      this.$router.push(`/manadRubricaPesquisa/${nCodOp}/`);
    },
  },

  computed: {
    sortedListaManads: function () {
      return this.listaManads.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    filteredListaManads: function () {
      let listaFiltrada = this.sortedListaManads.filter((manads) => {
        for (var manad in manads) {
          if (
            String(manads[manad]).indexOf(this.searchQuery.toUpperCase()) !== -1
          ) {
            return true;
          }
        }
        return false;
      });

      this.totalPages = Math.ceil(listaFiltrada.length / this.pageSize);
      return listaFiltrada.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
};
</script>

<style scoped></style>
